import { getDateString } from "@/methods/utils";
import ApiUnverified from "@/services/apiUnverified";
import { FilterableCardsStore, IdNamePair } from "@/types/global";
import { defineStore } from "pinia";

function getInitialFilterableCardsStore(): FilterableCardsStore {
  const filterbleCardsStore: FilterableCardsStore = {
    CategoryRadioButtons: [],
    FilterFields: [],
    SearchFields: [],
    FilterFieldOptions: [],
    IncludeDateFilter: false,
    SelectedFilters: [],
    SelectedDateRange: {},
    CurrentSearchText: null,
    AllCardsData: {},
    FilteredCardsData: {},
    CurrentCategoryRadioId: 0,
    CardComponentName: "",
    PaginationPageSize: 10,
    DateRangeDays: 7,
    CurrentPageNumber: 1,
    EnableDownload: false,
    IsCardsDataLoading: false,
    CardsDownloadData: [],
    CardsDownloadItems: null,
    CardsReloadKey: 0,
  };
  return JSON.parse(JSON.stringify(filterbleCardsStore));
}

export const useFilterableCardsStore = defineStore("filterableCardsStore", {
  state: (): FilterableCardsStore => getInitialFilterableCardsStore(),
  getters: {
    CurrentAllData(state) {
      if (state.CurrentCategoryRadioId === undefined) {
        return;
      }
      return state.AllCardsData[state.CurrentCategoryRadioId];
    },
    CurrentFilteredData(state) {
      if (state.CurrentCategoryRadioId === undefined) {
        return;
      }
      return state.FilteredCardsData[state.CurrentCategoryRadioId];
    },
  },
  actions: {
    resetCardsList() {
      this.AllCardsData = {};
      this.FilteredCardsData = {};
    },
    updateCardsList(cardsList: Array<unknown> | undefined) {
      if (this.CurrentCategoryRadioId === undefined) {
        this.CurrentCategoryRadioId = 0;
      }
      this.AllCardsData[this.CurrentCategoryRadioId] = cardsList ? cardsList : [];
      this.updateFilterOptions();
      this.updateFilteredCards();
    },
    async updateFilterOptions() {
      const foundIds = [];
      for (let f = 0; f < this.FilterFields.length; f++) {
        foundIds[f] = new Set<number>();
        this.FilterFieldOptions[f] = [];
      }
      if (!this.CurrentAllData) {
        return;
      }
      for (let c = 0; c < this.CurrentAllData.length; c++) {
        for (let f = 0; f < this.FilterFields.length; f++) {
          const fieldData = this.CurrentAllData[c][this.FilterFields[f]];
          if (Array.isArray(fieldData)) {
            for (let i = 0; i < fieldData.length; i++) {
              this.addFieldToOptions(this.FilterFieldOptions[f], fieldData[i], foundIds[f]);
            }
          } else {
            this.addFieldToOptions(this.FilterFieldOptions[f], fieldData, foundIds[f]);
          }
        }
      }
      for (let f = 0; f < this.FilterFields.length; f++) {
        if (!this.FilterFieldOptions[f]) {
          continue;
        }
        this.FilterFieldOptions[f].sort((a, b) => (a.Name >= b.Name ? 1 : -1));
      }
    },
    addFieldToOptions(
      optionsList: Array<IdNamePair>,
      fieldData: IdNamePair,
      foundIds: Set<number>,
    ) {
      if (!fieldData || foundIds.has(fieldData.Id)) {
        return;
      }
      foundIds.add(fieldData.Id);
      optionsList.push({ Id: fieldData.Id, Name: fieldData.Name });
    },
    updateFilteredCards() {
      if (this.CurrentCategoryRadioId === undefined || !this.CurrentAllData) {
        return;
      }
      const currentFilteredCardsData = [...this.CurrentAllData];
      this.FilteredCardsData[this.CurrentCategoryRadioId] = currentFilteredCardsData.filter(
        (card) => {
          let finalMatch = true;
          for (let f = 0; f < this.FilterFields.length; f++) {
            const selectedField = this.SelectedFilters[f];
            if (!selectedField) {
              continue;
            }
            const cardData = card[this.FilterFields[f]];
            if (Array.isArray(cardData)) {
              let isMatched = false;
              for (let c = 0; c < cardData.length; c++) {
                if (cardData[c].Id === selectedField) {
                  isMatched = true;
                  break;
                }
              }
              if (!isMatched) {
                finalMatch = false;
                break;
              }
            } else if (cardData.Id != selectedField) {
              finalMatch = false;
              break;
            }
          }
          if (this.SearchFields === null || !(finalMatch && this.CurrentSearchText)) {
            return finalMatch;
          }
          for (let s = 0; s < this.SearchFields.length; s++) {
            const searchCardData = card[this.SearchFields[s]];
            if (searchCardData === null) {
              continue;
            }
            const cardDataString =
              typeof searchCardData === "object" && searchCardData.Name
                ? searchCardData.Name
                : searchCardData.toString();
            finalMatch = cardDataString
              .toLowerCase()
              .includes(this.CurrentSearchText.toLowerCase());
            if (finalMatch) {
              break;
            }
          }
          return finalMatch;
        },
      );
      this.CardsDownloadData = Object.values(this.FilteredCardsData[this.CurrentCategoryRadioId]);
    },
    async setDateRangeForCategory(categoryRadioId: number, showOlder: boolean) {
      if (categoryRadioId === undefined) {
        return;
      }
      const currentRange = this.SelectedDateRange[categoryRadioId];
      let startDate, endDate;
      if (currentRange === undefined) {
        endDate = await new ApiUnverified().getServerTime();
        startDate = new Date(endDate);
        startDate.setDate(startDate.getDate() - this.DateRangeDays);
      } else {
        if (showOlder) {
          endDate = new Date(currentRange[0]);
          startDate = new Date(endDate);
          startDate.setDate(startDate.getDate() - this.DateRangeDays);
        } else {
          startDate = new Date(currentRange[1]);
          endDate = new Date(startDate);
          endDate.setDate(endDate.getDate() + this.DateRangeDays);
        }
      }
      this.SelectedDateRange[categoryRadioId] = [getDateString(startDate), getDateString(endDate)];
    },
  },
});
