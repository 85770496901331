import { handleApiError, handleApiResponse } from "@/methods/api";
import { useSessionStore } from "@/stores/sessionStore";
import {
  AddBulkUsers,
  AddBulkUsersResponse,
  AddEditAffiliation,
  AddEditBatch,
  AddEditUser,
  AEMSchoolContactForm,
  AEMSchoolContactFormDisplay,
  SchoolQueryForm,
  AffiliationActivityCounts,
  AffiliationDisplay,
  AffiliationUserCounts,
  BatchDisplay,
  StudentRollNumber,
  StudentUsageCounts,
  TeacherUsageCounts,
  UserDisplay,
  SchoolImplementationScore,
} from "@/types/admin";
import {
  AffiliationCourseBatch,
  AffiliationStatistic,
  BatchEffortAnalysisData,
  SyllabusBatchWiseData,
  SyllabusTeacherWiseData,
} from "@/types/affiliation";
import {
  AffiliationAssignmentReport,
  AffiliationAssignmentReportDetails,
  AssignmentData,
  AssignmentDesign,
  AssignmentStudent,
  PreloadedAssignmentInfo,
} from "@/types/assignment";
import {
  AssignmentEvent,
  AssignmentTimeStamps,
  ConductAssignmentInfo,
  ConductAssignmentState,
  ConductAssignmentStore,
} from "@/types/conductAssignment";
import {
  ApiResponse,
  IdNamePair,
  LoginWithAccessToken,
  NavbarMenu,
  UserAlert,
  UserInfo,
} from "@/types/global";
import { AssignmentReport, AssignmentReportDetails } from "@/types/studentAssignmentReport";
import {
  AVReport,
  LeaderboardCard,
  PracticeSessionInfo,
  RewardCoins,
} from "@/types/studentDashboard";
import {
  AddEditLecturePlan,
  BatchTeachingAssessments,
  ChapterLecturePlan,
  LecturePlan,
  SubjectTeachingVideos,
  TeachingAid,
} from "@/types/teachingContent";
import {
  ActivityWiseRecommendations,
  OverviewCard,
  PerformanceCard,
  PerformanceCards,
  PerformanceTrendsData,
} from "@/types/userMetrics";
import axios, { AxiosInstance } from "axios";

export default class ApiVerified {
  public axiosInstance: AxiosInstance;

  constructor(accessToken: string) {
    this.axiosInstance = axios.create({ baseURL: import.meta.env.VITE_API_URL });
    this.axiosInstance.interceptors.request.use(
      (config) => {
        config.headers["Authorization"] = `Bearer ${accessToken}`;
        return config;
      },
      (error) => Promise.reject(error),
    );

    this.axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        const originalRequest = error.config;
        const sessionStore = useSessionStore();
        if (error.response.status === 403 && !originalRequest._retry) {
          originalRequest._retry = true;
          const newAccessToken = await sessionStore.refreshAccessToken();
          originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
          return axios(originalRequest);
        }
        return Promise.reject(error);
      },
    );
  }

  async getNavbarMenu(userRoleId: number, affiliationId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<NavbarMenu>(
        `app-settings/get-web-navbar-menu`,
        {
          params: { userRoleId: userRoleId, affiliationId: affiliationId },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getUserAlerts(userRoleId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<UserAlert>>(
        `app-settings/get-user-alerts`,
        { params: { userRoleId: userRoleId } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async loginWithThirdPartyAccessToken(
    loginCredentials: LoginWithAccessToken,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<UserInfo>(
        `user/login-with-third-party-access-token`,
        loginCredentials,
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async changePassword(
    userId: number,
    currentPassword: string,
    newPassword: string,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(`user/change-password`, {
        userId: userId,
        currentPassword: currentPassword,
        newPassword: newPassword,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async resetUserPassword(
    userId: number,
    newPassword: string,
    setToDefault: boolean,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(`user/reset-user-password`, {
        userId: userId,
        newPassword: newPassword,
        setToDefault: setToDefault,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getChildAffiliations(affiliationId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<IdNamePair>>(
        `affiliation-batch/get-child-affiliations`,
        {
          params: { affiliationId: affiliationId, onlyActive: true },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getCourseBatchTreeForAffiliation(affiliationId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<AffiliationCourseBatch>>(
        `affiliation-batch/get-course-batch-tree`,
        {
          params: {
            affiliationId: affiliationId,
            onlyActive: true,
            includeChildren: true,
          },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getChapterPlanForBatchSubject(batchId: number, subjectId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<ChapterLecturePlan>>(
        `teaching-content/get-chapter-plan-for-batch-subject`,
        {
          params: { batchId: batchId, subjectId: subjectId },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getLecturePresentationPageImages(teachingLectureId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<string>>(
        `teaching-content/get-lecture-presentation-page-images`,
        {
          params: { teachingLectureId: teachingLectureId },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getTeachingVideosForCourse(affiliationId: number, courseId: number) {
    try {
      const response = await this.axiosInstance.get<Array<SubjectTeachingVideos>>(
        `teaching-content/get-teaching-videos-for-course`,
        {
          params: { affiliationId: affiliationId, courseId: courseId },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getLecturePlanForChapter(batchId: number, courseChapterId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<LecturePlan>>(
        `teaching-content/get-lecture-plan-for-chapter`,
        {
          params: { batchId: batchId, courseChapterId: courseChapterId },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getTeachingLecturesForChapter(courseChapterId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<IdNamePair>>(
        `teaching-content/get-teaching-lectures-for-chapter`,
        {
          params: { courseChapterId: courseChapterId },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getTeachingAidForChapter(courseChapterId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<TeachingAid>(
        `teaching-content/get-teaching-aid-for-chapter`,
        {
          params: { courseChapterId: courseChapterId },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async saveTeachingAidForChapter(
    courseChapterId: number,
    teachingAidData: TeachingAid,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `teaching-content/save-teaching-aid-for-chapter`,
        {
          courseChapterId: courseChapterId,
          teachingAidData: teachingAidData,
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async saveLecturePlanForChapter(
    courseChapterId: number,
    lecturePresentationPdfUrl: string,
    allLectures: Array<AddEditLecturePlan>,
    deletedLectures: Array<number> | null,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `teaching-content/save-lecture-plan-for-chapter`,
        {
          courseChapterId: courseChapterId,
          lecturePresentationPdfUrl: lecturePresentationPdfUrl,
          allLectures: allLectures,
          deletedLectures: deletedLectures,
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getTeachingAssessmentsForCourse(affiliationId: number, courseId: number) {
    try {
      const response = await this.axiosInstance.get<Array<BatchTeachingAssessments>>(
        `teaching-content/get-teaching-assessments-for-course`,
        {
          params: { affiliationId: affiliationId, courseId: courseId },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async updateLectureProgress(
    teachingLectureId: number,
    batchId: number,
    lectureStatusId: number,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `teaching-content/update-lecture-progress`,
        {
          teachingLectureId: teachingLectureId,
          batchId: batchId,
          lectureStatusId: lectureStatusId,
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async updateTeachingLectureClicks(
    teachingLectureId: number,
    userId: number,
    pdfClickTypeId: number,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `teaching-content/update-lecture-clicks`,
        {
          teachingLectureId: teachingLectureId,
          userId: userId,
          pdfClickTypeId: pdfClickTypeId,
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async updateTeachingAssignment(
    teachingAssignmentId: number,
    assignmentName: string,
    preloadedAssignmentId: number,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `teaching-content/update-teaching-assignment`,
        {
          teachingAssignmentId: teachingAssignmentId,
          assignmentName: assignmentName,
          preloadedAssignmentId: preloadedAssignmentId,
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async deleteTeachingAssignment(teachingAssignmentId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `teaching-content/delete-teaching-assignment`,
        { teachingAssignmentId: teachingAssignmentId },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async updateTeachingLectureName(
    teachingLectureId: number,
    lectureName: string,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `teaching-content/update-lecture-name`,
        { teachingLectureId: teachingLectureId, lectureName: lectureName },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async updateTeachingAssessment(
    teachingAssessmentId: number,
    assessmentName: string,
    preloadedTestId: number,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `teaching-content/update-teaching-assessment`,
        {
          teachingAssessmentId: teachingAssessmentId,
          assessmentName: assessmentName,
          preloadedTestId: preloadedTestId,
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async deleteTeachingAssessment(teachingAssessmentId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `teaching-content/delete-teaching-assessment`,
        { teachingAssessmentId: teachingAssessmentId },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async updateSessionLastAccessed(loginSessionId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(`user/update-session-last-accessed`, {
        loginSessionId: loginSessionId,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async submitDeleteAccountPermanently(userId: number, loginId: string, password: string) {
    try {
      const response = await this.axiosInstance.post<boolean>(`user/delete-account-permanently`, {
        userId: userId,
        loginId: loginId,
        password: password,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async checkIsParentAffiliation(affiliationId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<boolean>(
        `affiliation-batch/is-parent-affiliation`,
        {
          params: { affiliationId: affiliationId },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getDetailedAffiliationBatches(affiliationId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<BatchDisplay>>(
        `affiliation-batch/get-detailed-affiliation-batches`,
        {
          params: { affiliationId: affiliationId, includeChildren: true },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async addNewBatch(updateData: AddEditBatch): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `affiliation-batch/add-new-batch`,
        updateData,
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async updateBatch(batchId: number, updateData: AddEditBatch): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(`affiliation-batch/update-batch`, {
        batchId: batchId,
        updateData: updateData,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async updateBatchStatus(batchId: number, isActive: boolean): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `affiliation-batch/update-batch-status`,
        { batchId: batchId, isActive: isActive },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getAcademicSessions(onlyActive: boolean): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<IdNamePair>>(
        `affiliation-batch/get-academic-sessions`,
        { params: { onlyActive: onlyActive } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getOwnerAccounts(affiliationId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<IdNamePair>>(`user/get-owner-accounts`, {
        params: { affiliationId: affiliationId },
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async registerNewUser(userData: AddEditUser): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(`user/register-new-user`, userData);
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async registerBulkUsers(userData: AddBulkUsers, csvFile: string): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<AddBulkUsersResponse>(
        `user/register-bulk-users`,
        { userData: userData, csvFile: csvFile },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async updateUser(userId: number, updateData: AddEditUser): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(`user/update-user`, {
        userId: userId,
        updateData: updateData,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async updateUserStatus(userId: number, isActive: boolean): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(`user/update-user-status`, {
        userId: userId,
        isActive: isActive,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async updateBulkUsersBatch(batchId: number, userIds: Array<number>): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(`user/update-bulk-users-batch`, {
        batchId: batchId,
        userIds: userIds,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getAffiliationUsersByRoles(
    affiliationId: number,
    roleIdsCsv: string,
    isActive: boolean,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<UserDisplay>>(
        `affiliation-batch/get-affiliation-users-by-roles`,
        {
          params: { affiliationId: affiliationId, roleIdsCsv: roleIdsCsv, isActive: isActive },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getAffiliationBatchStudents(batchId: number, isActive: boolean): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<UserDisplay>>(
        `affiliation-batch/get-batch-students`,
        {
          params: { batchId: batchId, isActive: isActive },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getParentAffiliations(): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<IdNamePair>>(
        `admin/get-parent-affiliations`,
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getAffliationTypes(): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<IdNamePair>>(
        `admin/get-affiliation-types`,
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getCities(): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<IdNamePair>>(`common/get-all-cities`);
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getAffiliationsByType(
    affiliationTypeId: number,
    onlyActive: boolean,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<IdNamePair>>(
        `admin/get-affiliations-by-type`,
        {
          params: { affiliationTypeId: affiliationTypeId, onlyActive: onlyActive },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getAffiliationsByName(affiliationName: string): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<IdNamePair>>(
        `admin/get-affiliations-by-name`,
        {
          params: { affiliationName: affiliationName },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getAffiliationBatches(affiliationId: number, onlyActive: boolean): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<IdNamePair>>(
        `affiliation-batch/get-affiliation-batches`,
        {
          params: { affiliationId: affiliationId, onlyActive: onlyActive },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getAffiliationStatistics(affiliationId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<AffiliationStatistic>>(
        `affiliation-batch/get-affiliation-statistics`,
        {
          params: { affiliationId: affiliationId },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getDetailedAffiliationsByType(
    affiliationTypeId: number,
    onlyActive: boolean,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<AffiliationDisplay>(
        `admin/get-detailed-affiliations-by-type`,
        {
          params: { affiliationTypeId: affiliationTypeId, onlyActive: onlyActive },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getDetailedAffiliationsByName(affiliationName: string): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<AffiliationDisplay>(
        `admin/get-detailed-affiliations-by-name`,
        {
          params: { affiliationName: affiliationName },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async addNewAffiliation(affiliationData: AddEditAffiliation): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `admin/add-new-affiliation`,
        affiliationData,
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async updateAffiliation(affiliationData: AddEditAffiliation): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `admin/update-affiliation`,
        affiliationData,
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async updateAffiliationStatus(affiliationId: number, isActive: boolean): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(`admin/update-affiliation-status`, {
        affiliationId: affiliationId,
        isActive: isActive,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getAffiliationUserCounts(
    affiliationTypeId: number,
    startDate: string,
    endDate: string,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<AffiliationUserCounts>>(
        `admin/get-affiliation-user-counts`,
        {
          params: { affiliationTypeId: affiliationTypeId, startDate: startDate, endDate: endDate },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getAffiliationActivityCounts(
    affiliationTypeId: number,
    startDate: string,
    endDate: string,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<AffiliationActivityCounts>>(
        `admin/get-affiliation-activity-counts`,
        {
          params: { affiliationTypeId: affiliationTypeId, startDate: startDate, endDate: endDate },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getTeachersUsageData(
    affiliationId: number,
    startDate: string,
    endDate: string,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<TeacherUsageCounts>>(
        `admin/get-teacher-usage-counts`,
        {
          params: { affiliationId: affiliationId, startDate: startDate, endDate: endDate },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getStudentsUsageData(
    affiliationId: number,
    startDate: string,
    endDate: string,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<StudentUsageCounts>>(
        `admin/get-student-usage-counts`,
        {
          params: { affiliationId: affiliationId, startDate: startDate, endDate: endDate },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getPreloadedAssignmentsForAffiliation(courseId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<PreloadedAssignmentInfo>>(
        `assignment/preloaded-assignments-for-affiliation`,
        { params: { courseId: courseId } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async createAssignment(
    assignmentDesign: AssignmentDesign,
    affiliationId: number,
    courseId: number,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(`assignment/create-assignment`, {
        assignmentDesign: assignmentDesign,
        affiliationId: affiliationId,
        courseId: courseId,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getAssignmentDesign(assignmentId: number, affiliationId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<AssignmentDesign>(
        `assignment/get-assignment-design`,
        {
          params: { assignmentId: assignmentId, affiliationId: affiliationId },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async publishPreloadedAssignment(
    assignmentId: number,
    affiliationId: number,
    assignmentName: string,
    batchIds: Array<number>,
    startDatetime: string,
    endDatetime: string,
    teachingAssignmentId: number | null,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `assignment/publish-preloaded-assignment`,
        {
          assignmentId: assignmentId,
          affiliationId: affiliationId,
          assignmentName: assignmentName,
          batchIds: batchIds,
          startDatetime: startDatetime,
          endDatetime: endDatetime,
          teachingAssignmentId: teachingAssignmentId,
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getAssignmentsForAffliation(affiliationId: number, category: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<AssignmentData>(
        `assignment/get-assignments-for-affiliation`,
        {
          params: { affiliationId: affiliationId, category: category },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getAssignmentsForStudent(userId: number, category: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<AssignmentStudent>(
        `assignment/get-assignments-for-student`,
        {
          params: { userId: userId, category: category },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async cloneAssignment(
    assignmentId: number,
    assignmentName: string,
    affiliationId: number,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(`assignment/clone-assignment`, {
        assignmentId: assignmentId,
        assignmentName: assignmentName,
        affiliationId: affiliationId,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async mergeAssignments(
    assignmentIds: Array<number>,
    assignmentName: string,
    affiliationId: number,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(`assignment/merge-assignments`, {
        assignmentIds: assignmentIds,
        assignmentName: assignmentName,
        affiliationId: affiliationId,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async deleteAssignment(
    assignmentId: number,
    affiliationId: number,
    userId: number,
    userRole: string,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.delete<boolean>(`assignment/delete-assignment`, {
        params: {
          assignmentId: assignmentId,
          affiliationId: affiliationId,
          userId: userId,
          userRole: userRole,
        },
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async publishAssignment(
    assignmentId: number,
    affiliationId: number,
    batchIds: Array<number>,
    startDatetime: string,
    endDatetime: string,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(`assignment/publish-assignment`, {
        assignmentId: assignmentId,
        affiliationId: affiliationId,
        batchIds: batchIds,
        startDatetime: startDatetime,
        endDatetime: endDatetime,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async renameAssignment(assignmentId: number, assignmentName: string): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(`assignment/rename-assignment`, {
        assignmentId: assignmentId,
        assignmentName: assignmentName,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async rescheduleAssignment(
    assignmentId: number,
    batchIds: Array<number>,
    startDatetime: string,
    endDatetime: string,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(`assignment/reschedule-assignment`, {
        assignmentId: assignmentId,
        batchIds: batchIds,
        startDatetime: startDatetime,
        endDatetime: endDatetime,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getStudentAssignmentReport(
    assignmentUserId: number,
    userId: number,
    userRole: string,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<AssignmentReport>(
        `conduct-assignment/student-assignment-report`,
        {
          params: { assignmentUserId: assignmentUserId, userId: userId, userRole: userRole },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getStudentAssignmentReportDetails(
    assignmentUserId: number,
    userId: number,
    userRole: string,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<AssignmentReportDetails>(
        `conduct-assignment/student-assignment-report-details`,
        {
          params: { assignmentUserId: assignmentUserId, userId: userId, userRole: userRole },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getAffiliationAssignmentReport(
    assignmentId: number,
    userId: number,
    userRole: string,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<AffiliationAssignmentReport>(
        `conduct-assignment/affiliation-assignment-report`,
        {
          params: { assignmentId: assignmentId, userId: userId, userRole: userRole },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getAffiliationAssignmentReportDetails(
    assignmentId: number,
    userId: number,
    userRole: string,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<AffiliationAssignmentReportDetails>(
        `conduct-assignment/affiliation-assignment-report-details`,
        { params: { assignmentId: assignmentId, userId: userId, userRole: userRole } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async loadAssignment(assignmentId: number, userId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<ConductAssignmentStore>(
        `conduct-assignment/load-assignment`,
        { params: { assignmentId: assignmentId, userId: userId } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async startAssignment(assignmentUserId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<AssignmentTimeStamps>(
        `conduct-assignment/start-assignment`,
        { assignmentUserId: assignmentUserId },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async pushUnsavedAssignmentEvents(
    assignmentUserId: number,
    assignmentEvents: Array<AssignmentEvent>,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `conduct-assignment/push-assignment-events`,
        {
          assignmentUserId: assignmentUserId,
          assignmentEvents: assignmentEvents,
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async endAssignment(
    assignmentInfo: ConductAssignmentInfo,
    assignmentState: ConductAssignmentState,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(`conduct-assignment/end-assignment`, {
        AssignmentInfo: assignmentInfo,
        AssignmentState: assignmentState,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getUsersBySearchText(affiliationId: number, searchText: string, batchId: number | null) {
    try {
      const response = await this.axiosInstance.get<UserDisplay>(`user/users-by-search-text`, {
        params: { affiliationId: affiliationId, searchText: searchText, batchId: batchId },
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getUsersByLogin(searchText: string) {
    try {
      const response = await this.axiosInstance.get<UserDisplay>(`user/users-by-login`, {
        params: { searchText: searchText },
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getPreloadedAssignmentById(preloadedAssignmentId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<boolean>(
        `assignment/preloaded-assignment-by-id`,
        {
          params: { preloadedAssignmentId: preloadedAssignmentId },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getBatchWiseSyllabusCoverage(
    affiliationId: number,
    startDate: string,
    endDate: string,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<SyllabusBatchWiseData>>(
        `affiliation-report/batch-wise-syllabus-coverage`,
        {
          params: { affiliationId: affiliationId, startDate: startDate, endDate: endDate },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getTeacherWiseSyllabusCoverage(
    affiliationId: number,
    startDate: string,
    endDate: string,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<SyllabusTeacherWiseData>>(
        `affiliation-report/teacher-wise-syllabus-coverage`,
        {
          params: { affiliationId: affiliationId, startDate: startDate, endDate: endDate },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getBatchEffortAnalysis(
    batchId: number,
    startDate: string,
    endDate: string,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<BatchEffortAnalysisData>(
        `affiliation-report/batch-wise-effort-analysis`,
        {
          params: { batchId: batchId, startDate: startDate, endDate: endDate },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getBatchPerformanceOverviewCards(
    batchId: number,
    subjectId: number,
    isDemoUser: boolean,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<OverviewCard>>(
        `affiliation-report/get-batch-performance-overview-cards`,
        {
          params: { batchId: batchId, subjectId: subjectId, isDemoUser: isDemoUser },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getBatchPerformanceStudentWiseCards(
    batchId: number,
    subjectId: number,
    isDemoUser: boolean,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<PerformanceCards>(
        `affiliation-report/get-batch-performance-studentwise-cards`,
        {
          params: { batchId: batchId, subjectId: subjectId, isDemoUser: isDemoUser },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getBatchPerformanceChapterWiseCards(
    batchId: number,
    subjectId: number,
    isDemoUser: boolean,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<PerformanceCards>(
        `affiliation-report/get-batch-performance-chapterwise-cards`,
        {
          params: { batchId: batchId, subjectId: subjectId, isDemoUser: isDemoUser },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getTeacherRecommendationCards(
    batchId: number,
    isDemoUser: boolean,
    classId: number | null,
    subjectId: number | null,
    activityName: string | null,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<ActivityWiseRecommendations>(
        `affiliation-report/get-teacher-recommendation-cards`,
        {
          batchId: batchId,
          isDemoUser: isDemoUser,
          classId: classId,
          subjectId: subjectId,
          activityName: activityName,
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getBatchLeaderboard(
    batchId: number,
    isDemoUser: boolean,
    subjectId: number | null,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<LeaderboardCard>>(
        `/affiliation-report/get-batch-leaderboard`,
        {
          params: {
            batchId: batchId,
            isDemoUser: isDemoUser,
            subjectId: subjectId,
          },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getStudentSubjectOverviewCards(
    userId: number,
    courseId: number,
    classId: number,
    subjectId: number,
    isDemoUser: boolean,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<OverviewCard>>(
        `user-metrics/get-student-overview-cards`,
        {
          params: {
            userId: userId,
            courseId: courseId,
            classId: classId,
            subjectId: subjectId,
            isDemoUser: isDemoUser,
          },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getStudentChapterwiseCards(
    userId: number,
    courseId: number,
    classId: number,
    subjectId: number,
    includeAllChapters: boolean,
    isDemoUser: boolean,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<PerformanceCards>(
        `/user-metrics/get-student-chapterwise-cards`,
        {
          params: {
            userId: userId,
            courseId: courseId,
            classId: classId,
            subjectId: subjectId,
            includeAllChapters: includeAllChapters,
            isDemoUser: isDemoUser,
          },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getStudentRecommendations(
    userId: number,
    courseId: number,
    classId: number,
    subjectId: number | null,
    activityName: string | null,
    isDemoUser: boolean,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<ActivityWiseRecommendations>(
        `/user-metrics/get-student-recommendation-cards`,
        {
          userId: userId,
          courseId: courseId,
          classId: classId,
          subjectId: subjectId,
          activityName: [activityName],
          isDemoUser: isDemoUser,
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getStudentLeaderboard(
    userId: number,
    courseId: number,
    classId: number,
    isDemoUser: boolean,
    subjectId: number | null,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<LeaderboardCard>>(
        `/user-metrics/get-student-leaderboard`,
        {
          params: {
            userId: userId,
            courseId: courseId,
            classId: classId,
            isDemoUser: isDemoUser,
            subjectId: subjectId,
          },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getRewardCoinsForStudent(userId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<RewardCoins>(
        `/user-metrics/get-student-reward-coins`,
        { params: { userId: userId } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getStudentAVReportsSubjectCoverage(
    userId: number,
    courseId: number,
    classId: number,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<AVReport>>(
        `/user-metrics/get-student-av-reports-subject-coverage`,
        { params: { userId: userId, courseId: courseId, classId: classId } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getStudentSubjectTrends(
    userId: number,
    courseId: number,
    classId: number,
    subjectId: number,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<PerformanceTrendsData>(
        `user-metrics/get-student-subject-trends`,
        {
          params: { userId: userId, courseId: courseId, classId: classId, subjectId: subjectId },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getStudentSubjectCards(
    userId: number,
    courseId: number,
    classId: number,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<PerformanceCard>>(
        `user-metrics/get-student-subject-cards`,
        {
          params: { userId: userId, courseId: courseId, classId: classId },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getWeeklyStudentUsage(
    affiliationTypeId: number,
    startDate: string | null,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<object>>(
        `admin/get-weekly-batch-student-usage`,
        { params: { affiliationTypeId: affiliationTypeId, startDate: startDate } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getWeeklyTeacherUsage(
    affiliationTypeId: number,
    startDate: string | null,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<object>>(
        `admin/get-weekly-batch-teacher-usage`,
        { params: { affiliationTypeId: affiliationTypeId, startDate: startDate } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getSchoolImplementationScores(
    affiliationTypeId: number,
    startDate: string | null,
    endDate: string | null,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<SchoolImplementationScore>>(
        `admin/get-school-implementation-scores`,
        {
          params: { affiliationTypeId: affiliationTypeId, startDate: startDate, endDate: endDate },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async migrateMultipleNewAssignmentsToPreloaded(
    assignmentIds: Array<number> | null,
    preloadedCategoryId: number,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `assignment/migrate-multiple-new-assignments-to-preloaded`,
        {
          assignmentIds: assignmentIds,
          preloadedCategoryId: preloadedCategoryId,
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getSelfPracticePendingSessions(
    userId: number,
    courseId: number,
    classId: number,
    subjectId: number,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<PracticeSessionInfo>>(
        `/self-practice/get-practice-pending-sessions`,
        {
          params: { userId: userId, courseId: courseId, classId: classId, subjectId: subjectId },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getSelfPracticeCompletedSessions(
    userId: number,
    courseId: number,
    classId: number,
    subjectId: number,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<PracticeSessionInfo>>(
        `/self-practice/get-practice-completed-sessions`,
        {
          params: { userId: userId, courseId: courseId, classId: classId, subjectId: subjectId },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getStudentRollNumbers(batchId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<StudentRollNumber>>(
        `affiliation-batch/get-student-roll-numbers`,
        { params: { batchId: batchId } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async saveStudentRollNumbers(studentRollNumbers: Array<StudentRollNumber>): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<Array<StudentRollNumber>>(
        `affiliation-batch/save-student-roll-numbers`,
        studentRollNumbers,
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async saveSchoolQueryForm(schoolQueryForm: SchoolQueryForm): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `/admin/save-school-query-form`,
        schoolQueryForm,
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async updateSchoolQueryForm(schoolQueryForm: SchoolQueryForm): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `/admin/update-school-query-form`,
        schoolQueryForm,
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getSchoolQueryFormsForAffiliation(affiliationId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<SchoolQueryForm>>(
        `admin/get-school-query-forms-for-affiliation`,
        { params: { affiliationId: affiliationId } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getSchoolQueryFormsForOwner(ownerId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<SchoolQueryForm>>(
        `admin/get-school-query-forms-for-owner`,
        { params: { ownerId: ownerId } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getAEMSchoolContactFormsForUser(userId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<AEMSchoolContactFormDisplay>>(
        `admin/get-school-contact-forms-for-user`,
        { params: { userId: userId } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getAEMSchoolContactFormsForAffiliation(affiliationId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<AEMSchoolContactFormDisplay>>(
        `admin/get-school-contact-forms-for-affiliation`,
        { params: { affiliationId: affiliationId } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async saveAEMSchoolContactForm(schoolContactForm: AEMSchoolContactForm): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `/admin/save-school-contact-form`,
        schoolContactForm,
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async updateAEMSchoolContactForm(schoolContactForm: AEMSchoolContactForm): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `/admin/update-school-contact-form`,
        schoolContactForm,
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }
}
