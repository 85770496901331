import { AllAdminRoles } from "@/constants/userRoles";
export default [
  {
    path: "/admin/dashboard",
    component: () =>
      import(/* webpackChunkName: 'AdminDashboard' */ "@/views/admin/AdminDashboard.vue"),
    name: "AdminDashboard",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllAdminRoles,
      showAffiliationSelector: true,
    },
  },
  {
    path: "/admin/manage-affiliations",
    component: () =>
      import(
        /* webpackChunkName: 'AdminManageAffiliation' */ "@/views/admin/AdminManageAffiliations.vue"
      ),
    name: "AdminManageAffiliation",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllAdminRoles,
      showAffiliationSelector: true,
    },
  },
  {
    path: "/admin/platform-usage",
    component: () =>
      import(/* webpackChunkName: 'AdminPlatformUsage' */ "@/views/admin/AdminPlatformUsage.vue"),
    name: "AdminPlatformUsage",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllAdminRoles,
      showAffiliationSelector: true,
    },
  },
  {
    path: "/admin/school-implementation-score",
    component: () =>
      import(
        /* webpackChunkName: 'SchoolImplementationScore' */ "@/views/admin/SchoolImplementationScore.vue"
      ),
    name: "SchoolImplementationScore",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllAdminRoles,
    },
  },
];
