import { UserRolesEnum, UserRoleIdsEnum } from "@/enums/global";

export const AllUserRoles: ReadonlyArray<string> = Object.freeze([
  UserRolesEnum.ACADEMIC_EXCELLENCE_MANAGER,
  UserRolesEnum.ADMIN,
  UserRolesEnum.AFFILIATION_HEAD,
  UserRolesEnum.B2C_INDIVIDUAL_TEACHER,
  UserRolesEnum.CENTER_MANAGER,
  UserRolesEnum.CONTENT_HEAD,
  UserRolesEnum.CONTENT_MANAGER,
  UserRolesEnum.DEACTIVE_DEMO_STUDENT,
  UserRolesEnum.DEMO_STUDENT,
  UserRolesEnum.FACULTY,
  UserRolesEnum.MODERATOR,
  UserRolesEnum.PARENT,
  UserRolesEnum.STUDENT,
  UserRolesEnum.SCHOOL_TEACHER,
  UserRolesEnum.TUTOR,
  UserRolesEnum.TUTORING_ADMIN,
  UserRolesEnum.TUTORING_OPS,
  UserRolesEnum.TUTORING_SALES,
  UserRolesEnum.TUTORING_STUDENT,
]);

export const AllStudentRoles: ReadonlyArray<string> = Object.freeze([
  UserRolesEnum.DEMO_STUDENT,
  UserRolesEnum.STUDENT,
  UserRolesEnum.TUTORING_STUDENT,
]);

export const AllTeacherRoles: ReadonlyArray<string> = Object.freeze([
  UserRolesEnum.FACULTY,
  UserRolesEnum.SCHOOL_TEACHER,
  UserRolesEnum.TUTOR,
]);

export const AllAdminRoles: ReadonlyArray<string> = Object.freeze([UserRolesEnum.ADMIN]);

export const AllAffiliationRoles: ReadonlyArray<string> = Object.freeze([
  UserRolesEnum.AFFILIATION_HEAD,
  UserRolesEnum.FACULTY,
  UserRolesEnum.SCHOOL_TEACHER,
  UserRolesEnum.TUTOR,
]);

export const AllManagerRoles: ReadonlyArray<string> = Object.freeze([
  UserRolesEnum.ADMIN,
  UserRolesEnum.AFFILIATION_HEAD,
  UserRolesEnum.CENTER_MANAGER,
  UserRolesEnum.TUTORING_ADMIN,
]);

export const AllTutoringOpsRoles: ReadonlyArray<string> = Object.freeze([
  UserRolesEnum.TUTORING_ADMIN,
  UserRolesEnum.TUTORING_OPS,
  UserRolesEnum.TUTORING_SALES,
]);

export const AllAEMRoles: ReadonlyArray<string> = Object.freeze([
  UserRolesEnum.ADMIN,
  UserRolesEnum.ACADEMIC_EXCELLENCE_MANAGER,
]);

export const AllContentRoles: ReadonlyArray<string> = Object.freeze([
  UserRolesEnum.ADMIN,
  UserRolesEnum.CONTENT_HEAD,
  UserRolesEnum.CONTENT_MANAGER,
  UserRolesEnum.FACULTY,
]);

export const ContentApproverRoles: ReadonlyArray<string> = Object.freeze([
  UserRolesEnum.ADMIN,
  UserRolesEnum.CONTENT_HEAD,
  UserRolesEnum.FACULTY,
]);

export const AddEditUserRoleIds: ReadonlyArray<number> = Object.freeze([
  UserRoleIdsEnum.STUDENT,
  UserRoleIdsEnum.DEMO_STUDENT,
  UserRoleIdsEnum.SCHOOL_TEACHER,
  UserRoleIdsEnum.AFFILIATION_HEAD,
]);
